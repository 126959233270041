import React from "react";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Seo from "../../components/seo";
import { useAuthUser } from "../../components/Session";

const DESCRIPTION = `Check out Kristie Lengel's blog for balanced lifestyle guidance, recipes and workout tips! Her "no quick fix" approach helps you build healthy habits for life!`;

function BlogIndexPage() {
  const authUser = useAuthUser();
  const memberExperience = authUser && !!authUser.uid;

  // new layout
  if (!!memberExperience) {
    return (
      <div className="blog-roll">
        <Seo title="Blog" description={DESCRIPTION} />
        <BlogRoll member={true} />
      </div>
    );
  }

  // legacy layout
  return (
    <>
      <Seo title="Blog" description={DESCRIPTION} />
      <BlogRoll />
    </>
  );
}

const BlogIndexPageFinal = () => (
  <Layout>
    <BlogIndexPage />
  </Layout>
);

export default BlogIndexPageFinal;
